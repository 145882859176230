var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-basic')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-number-gen')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-array')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-button-content')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-goto')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-size')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-pill')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('pagination-nav-alignment')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }