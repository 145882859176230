var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Page number generation"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeNumberGen) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default, ")]), _c('code', [_vm._v("<b-pagination-nav>")]), _c('span', [_vm._v(" renders page numbers (1-N) in the page link buttons. You can override this behaviour by supplying a function reference to the ")]), _c('code', [_vm._v("page-gen")]), _c('span', [_vm._v(" property.")])]), _c('b-pagination-nav', {
    attrs: {
      "link-gen": _vm.linkGen,
      "page-gen": _vm.pageGen,
      "number-of-pages": _vm.links.length
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }