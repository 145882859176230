var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("<b-pagination-nav>")]), _c('span', [_vm._v(" is a custom input component that provides navigational pagination. The total number of pages set with the ")]), _c('code', [_vm._v("number-of-pages")]), _c('span', [_vm._v(" prop.")])]), _c('b-pagination-nav', {
    staticClass: "mb-0",
    attrs: {
      "link-gen": _vm.linkGen,
      "number-of-pages": 10,
      "use-router": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }